import React from "react";
import { images_url } from "../../Constants/constants";
import Header from "../../Widgets/Header/Header";
import Preloader from "../../Components/Preloader/Preloader";
import NavBar from "../../Widgets/NavBar/NavBar";
import Footer from "../../Widgets/Footer/Footer";
import GoToTop from "../../Functions/GotToTop";


function SocialImpact() {
  return (
    <>
      <Preloader />
      <div>
      </div>
      <div>
        <Header
          background={images_url.social_impact}
          title={"SOCIAL IMPACT"}
          subtitle={
            "Beyond coffee, there are people."
          }
        />
      </div>
      <Footer/>
      <GoToTop />

    </>
  )
}

export default SocialImpact