import React, { useEffect, useState } from "react";
import "./Preloader.css";
import { images_url } from "../../Constants/constants";

const images_map = {
  banner:
    "https://images.unsplash.com/photo-1697299708650-e4d1ce150d38?auto=format&fit=crop&q=80&w=1744&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  farm: "https://moplaco.com/assets/img/banner/detailBanner.jpg",
};

function Preloader() {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const loa = async () => {
      const images = Object.values(images_url);
      for (var i = 0; i < images.length; i++) {
        await preloadImage(images[i]);
      }
      setLoaded(true);
    };
    loa();
  }, []);
  return (
    <div
      className={`preloader --flex-center-center ${loaded && "--display-none"}`}
    >
      <div
        className="logo-preloader"
        style={{
          backgroundImage: `url(${images_url.logo_black})`,
        }}
      ></div>
    </div>
  );
}
const preloadImage = async (src) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.src = src;
    window[src] = image;
    image.style.display = "none";
    image.onload = () => {
      document.body.removeChild(image);
      resolve();
    };
    image.onerror = reject;
    document.body.appendChild(image);
  });
export default Preloader;
