export const images_url = {
  logo_black: "https://moplaco.com/assets/img/logo/black_moplaco.png",
  logo_white: "https://moplaco.com/assets/img/logo/moplaco.png",
  banner:
    "https://moplaco.com/assets/img/banner/homepagebanner.jpg",
  farm: "https://moplaco.com/assets/img/banner/detailBanner.jpg",
  export_truck:"https://moplaco.com/assets/img/banner/exporter.png",
  processor_man:"https://moplaco.com/assets/img/banner/processor.png",
  farmer_man:"https://moplaco.com/assets/img/banner/farmer.png",
  our_farm:"https://moplaco.com/assets/img/banner/our_farm.jpg",
  our_history_banner:"https://moplaco.com/assets/img/banner/about.jpg",
  our_team_banner:"https://moplaco.com/assets/img/banner/team.jpeg",
  what_we_do_banner:"https://moplaco.com/assets/img/banner/farm12.jpg",
  social_impact:"https://moplaco.com/assets/img/banner/community1.jpg",
    ethiopian_trade:"https://moplaco.com/assets/img/banner/about_banner.jpg",
    coffee_science_banner:"https://moplaco.com/assets/img/banner/coffee_s.jpg",
    did_you_know_banner:"https://moplaco.com/assets/img/banner/diduknow.jpg",
};
