import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./Scenes/Home/Home";
import OurHistory from "./Scenes/OurHistory/OurHistory";
import NavBar from "./Widgets/NavBar/NavBar";
import OutTeam from "./Scenes/OurTeam/OutTeam";
import WhatWeDo from "./Scenes/WhatWeDo/WhatWeDo";
import SocialImpact from "./Scenes/SocialImpact/SocialImpact";
import EthiopianTrade from "./Scenes/EthiopianTrade/EthiopianTrade";
import CoffeeMap from "./Scenes/CoffeeMap/CoffeeMap";
import CoffeeScience from "./Scenes/CoffeeScience/CoffeeScience";
import DidYouKnow from "./Scenes/DidYouKnow/DidYouKnow";
import Updates from "./Scenes/Updates/Updates";

function App() {
  return (
    <Router>
      <NavBar/>

    <Routes>
      <Route
        path="/"
        element={
         <Home/>
        }
      />
      <Route
        path="/our-history"
        element={
         <OurHistory/>
        }
      />
      <Route
        path="/our-team"
        element={
         <OutTeam/>
        }
      />
      <Route
        path="/what-we-do"
        element={
         <WhatWeDo/>
        }
      />
      <Route
        path="/social-impact"
        element={
         <SocialImpact/>
        }
      />
      <Route
        path="/trade"
        element={
         <EthiopianTrade/>
        }
      />
      <Route
        path="/coffee-map"
        element={
         <CoffeeMap/>
        }
      />
      <Route
        path="/coffee-science"
        element={
         <CoffeeScience/>
        }
      />
      <Route
        path="/didyouknow"
        element={
         <DidYouKnow/>
        }
      />
      <Route
        path="/updates"
        element={
         <Updates/>
        }
      />
      </Routes>
      </Router>
  );
}

export default App;
