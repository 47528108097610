import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./MobileMenu.css";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
function MobileMenu({ handleClose, isMenuOpen }) {
  const routePath = useLocation();
  const [isSubMenuOneOpen, setIsSubMenuOneOpen] = useState(false);
  const [isSubMenuTwoOpen, setIsSubMenuTwoOpen] = useState(false);
  return (
    <div className={`${isMenuOpen ? "mobile-menu" : "mobile-menu-out"}`}>
      <div className="mobile-header --flex-center-center --bold">
        {" "}
        MOPLACO{" "}
        <CloseIcon
          onClick={() => {
            handleClose();
          }}
          className="menu-close-icon"
        />
      </div>
      <div className="mobile-menu-list --bold">
        {routePath.pathname != "/" && (
          <div className="mobile-menu-link">
            {" "}
            <Link
              to={"/"}
              className="menu-text"
              onClick={() => {
                handleClose();
              }}
            >
              HOME
            </Link>
          </div>
        )}
        <div className="mobile-menu-link">
          {" "}
          <Link to={""} className="menu-text">
            WHO WE ARE & WHAT WE DO
            <div
              className={
                isSubMenuOneOpen ? `mobile-sub-menu` : "mobile-sub-menu closed"
              }
            >
              <div className="triangle-mobile">
                <div className="triangle-point-mobile" />
              </div>

              <ul className="--flex-center-center --column">
                <li>
                  <Link to={"/our-history"} className="--clean-btn"
                   onClick={() => {
                    handleClose();
                  }}
                  >
                    OUR HISTORY
                  </Link>
                </li>
                <div className="hr" />
                <li>
                <Link to={"/our-team"} className="--clean-btn"
                   onClick={() => {
                    handleClose();
                  }}
                  >
                    TEAM
                  </Link>
                </li>
                <div className="hr" />
                <li>
                <Link to={"/what-we-do"} className="--clean-btn"
                   onClick={() => {
                    handleClose();
                  }}
                  >
                    WHAT WE DO
                  </Link>
                </li>
                <div className="hr" />
                <li>
                <Link to={"/social-impact"} className="--clean-btn"
                   onClick={() => {
                    handleClose();
                  }}
                  >
                   SOCIAL IMPACT
                  </Link>
                </li>
                <div className="hr" />
                <li>
                <Link to={"/#partners"} className="--clean-btn"
                   onClick={() => {
                    handleClose();
                  }}
                  >
                   PARTNERS
                  </Link>
                </li>
              </ul>
            </div>
          </Link>
          <AddIcon
            className="add-icon-mobile-menu"
            onClick={() => setIsSubMenuOneOpen(!isSubMenuOneOpen)}
          />
        </div>
        <div className="mobile-menu-link">
          {" "}
          <Link to={""} className="menu-text"
           onClick={() => {
            handleClose();
          }}
          >
            ETHIOPIAN COFFEE INSIGHTS

            <div
              className={
                isSubMenuTwoOpen ? `mobile-sub-menu` : "mobile-sub-menu closed"
              }
            >
              <div className="triangle-mobile">
                <div className="triangle-point-mobile" />
              </div>

              <ul className="--flex-center-center --column">
                <li>
                  <Link to={"/trade"} className="--clean-btn"
                   onClick={() => {
                    handleClose();
                  }}
                  >
                   HISTORY of ETHIOPIAN TRADE
                  </Link>
                </li>
                
                <div className="hr" />
                <li>
                <Link to={"/coffee-map"} className="--clean-btn"
                   onClick={() => {
                    handleClose();
                  }}
                  >
                   ETHIOPIAN COFFEE MAP
                  </Link>
                </li>
                <div className="hr" />
                <li>
                <Link to={"/coffee-science"} className="--clean-btn"
                   onClick={() => {
                    handleClose();
                  }}
                  >
                   SCIENCE of COFEE
                  </Link>
                </li>
                <div className="hr" />
                <li>
                <Link to={"/didyouknow"} className="--clean-btn"
                   onClick={() => {
                    handleClose();
                  }}
                  >
                   DID YOU KNOW?
                  </Link>
                </li>
              </ul>
            </div>
          </Link>
          <AddIcon
            className="add-icon-mobile-menu"
            onClick={() => setIsSubMenuTwoOpen(!isSubMenuTwoOpen)}
          />
        </div>
        <div className="mobile-menu-link">
          {" "}
          <Link to={"/updates"} className="menu-text"
           onClick={() => {
            handleClose();
          }}
          >
            UPDATES
          </Link>
        </div>
        <div className="mobile-menu-link">
          {" "}
          <Link to={""} className="menu-text"
           onClick={() => {
            handleClose();
          }}
          >
            WORK WITH US
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
