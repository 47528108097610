import React from "react";
import { images_url } from "../../Constants/constants";
import Header from "../../Widgets/Header/Header";
import Preloader from "../../Components/Preloader/Preloader";
import NavBar from "../../Widgets/NavBar/NavBar";
import Footer from "../../Widgets/Footer/Footer";
import GoToTop from "../../Functions/GotToTop";

function OutTeam() {
  return (
    <>
    <Preloader />
    <div>
    </div>
    <div>
      <Header
        background={images_url.our_team_banner}
        title={"OUR TEAM"}
        subtitle={
          "Alone we can do so little, together we can do so much!"
        }
      />
    </div>
    <Footer/>
    <GoToTop />

  </>
  )
}

export default OutTeam