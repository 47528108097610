import React from "react";
import { images_url } from "../../Constants/constants";
import Header from "../../Widgets/Header/Header";
import Preloader from "../../Components/Preloader/Preloader";
import NavBar from "../../Widgets/NavBar/NavBar";
import Footer from "../../Widgets/Footer/Footer";
import GoToTop from "../../Functions/GotToTop";

function EthiopianTrade() {
  return (
    <>
    <Preloader />
    <div>
    </div>
    <div>
      <Header
        background={images_url.ethiopian_trade}
        // title={"OUR HISTORY"}
        // subtitle={
        //   "Born in the mountains of harrar in 1910 and established in 1971, our company has a long history of excellence."
        // }
      />
    </div>
    <Footer/>
    <GoToTop />

  </>
  )
}

export default EthiopianTrade