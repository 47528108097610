import React from "react";
import "./Footer.css";
import logo from "../../Assets/black_moplaco.png";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div className="footer-section">
      <div className="main --flex-center">
        <div className="description --flex-center footer-child">
          <img src={logo} alt="" />
          <p className="moplaco-text  --bold">Molpaco Trading PLC</p>
          <p className="--bold">
            Born in the mountains of Harar-Hirna, in 1940, our owner's soul and
            future was engraved into coffee.
          </p>
          <Link to={"/our-history"} className="--bold">
            Read More
          </Link>
        </div>
        <div className="contact footer-child --flex-center  --bold">
          <p className="footer-title --bold">Contact</p>
          <p className="footer-sub-title">Addis Ababa</p>
          <ul>
            <li>Phone: +251-116455999 | +251-116456008</li>
            <li>Fax: +251-116456012</li>
          </ul>
          <div className="hr" />
          <p className="footer-sub-title">Dire Dawa</p>
          <ul>
            <li>Phone: +251-25113453 | +251-25113971</li>
            <li>Fax: +251-116456012</li>
          </ul>
          <div className="hr" />
          <ul>
            <li style={{ paddingTop: "30px" }}>Email: info@moplaco.com</li>
          </ul>
        </div>
        <div className="hours footer-child --flex-center  --bold">
          <p className="footer-title  --bold">Hours</p>
          <ul>
            <li>Monday - Friday: 08:30 am - 05:30 pm</li>
            <li>Saturday: 08:30 am - 12:30 pm</li>
          </ul>
          <ul>
            <p className="footer-title  --bold">Contact Us On</p>

            <li>Phone: +251-25113453 | +251-25113971</li>
            <li>Fax: +251-116456012</li>
          </ul>
        </div>
      </div>
      <div className="copyright --flex-center">
        <p className="--bold">
          {" "}
          © Copyright 2022 <span> MOPLACO TRADING PLC </span> All right reserved
        </p>
      </div>
    </div>
  );
}

export default Footer;
