import React from "react";
import { images_url } from "../../Constants/constants";
import Header from "../../Widgets/Header/Header";
import Preloader from "../../Components/Preloader/Preloader";
import NavBar from "../../Widgets/NavBar/NavBar";
import Footer from "../../Widgets/Footer/Footer";
import GoToTop from "../../Functions/GotToTop";

function WhatWeDo() {
  return (
    <>
    <Preloader />
    <div>
    </div>
    <div>
      <Header
        background={images_url.what_we_do_banner}
        title={"WHAT WE DO"}
        subtitle={
          "Growing, nurturing & cultivating our future, one seed at a time"
        }
      />
    </div>
    <Footer/>
    <GoToTop />

  </>
  )
}

export default WhatWeDo