import React, { useEffect, useState, useRef } from "react";
import "./Navbar.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useInView } from "react-intersection-observer";
import { images_url } from "../../Constants/constants";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import useWindowDimensions from "../../Functions/useWindow";
import MobileMenu from "./MobileMenu";

function NavBar({}) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);
  const nav_bar_ref = useRef(null);
  const { width } = useWindowDimensions();

  const { refSlid, inView, entry } = useInView({
    /* Optional options */
    rootMargin: "-100px",
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { rootMargin: "100px" }
    );
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [isIntersecting]);

  useEffect(() => {
    if (isIntersecting) {
      nav_bar_ref.current.querySelectorAll("div").forEach((el) => {
        el.classList.add("hero-part");
      });
    } else {
      nav_bar_ref.current.querySelectorAll("div").forEach((el) => {
        el.classList.remove("hero-part");
      });
    }
  }, [isIntersecting]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuOpen = (isOpen) => {
    setIsMenuOpen(isOpen);
    console.log(isOpen);
  };

  useEffect(()=>{

  },[width])
  return (
    <>
      <div ref={ref}></div>
      <div ref={refSlid}></div>
      <div ref={nav_bar_ref}>
        <MobileMenu
          isMenuOpen={isMenuOpen}
          handleClose={() => {
            handleMenuOpen(false);
          }}
          className={isMenuOpen ? "slid-in-menu" : "slid-out-menu"}
        />

        <div
          className={`nav-bar hero-part --flex-center --bold ${
            !inView && "slid-in"
          }`}
        >
          <div
            style={{
              cursor: "pointer",
            }}
          >
            {width < 1200 && (
              <MenuIcon
                onClick={() => {
                  handleMenuOpen(true);
                }}
                style={{
                  cursor: "pointer !important",
                  display: "flex !important",
                  position: "absolute",
                  left: "30px",
                  top: "35px",
                  border: "30px solid red !important",
                  zIndex: "100 !important",
                  color: `${isIntersecting ? "white" : "0000"}`,
                }}
              />
            )}
          </div>
          <div className="logo-container --flex-center">
            <a href="/" className="--no-underline">
              {" "}
              <div
                className="logo"
                style={{
                  backgroundImage: `url(${
                    isIntersecting
                      ? images_url.logo_white
                      : images_url.logo_black
                  })`,
                }}
              >
                {/* <img src={logo} alt="logo" /> */}
              </div>
            </a>
            <p className="moplaco-text">Molpaco Trading PLC</p>
          </div>

          <div className=" ">
            <ul className="nav-links --flex-center">
              <li>
                <p className="--flex-center">
                  WHO WE ARE & WHAT WE DO{" "}
                  {<KeyboardArrowDownIcon style={{ padding: "0 0 0 15px" }} />}
                </p>
                <div className="sub-menu">
                  <div className="triangle">
                    <div className="triangle-point"></div>
                  </div>
                  <ul className="sub-links --flex-center">
                    <li>
                      <Link to={"/our-history"} className="--clean-btn">
                        OUR HISTORY
                      </Link>
                    </li>
                    <div className="hr" />
                    <li>
                      <Link to={"/our-team"} className="--clean-btn">
                        TEAM
                      </Link>
                    </li>
                    <div className="hr" />
                    <li>
                      <Link to={"/what-we-do"} className="--clean-btn">
                        WHAT WE DO
                      </Link>
                    </li>
                    <div className="hr" />
                    <li>
                      <Link to={"/social-impact"} className="--clean-btn">
                        SOCIAL IMPACT
                      </Link>
                    </li>
                    <div className="hr" />
                    <li>
                      <Link to={"/#partners"} className="--clean-btn">
                        PARTNERS
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <p className="--flex-center">
                  ETHIOPIAN COFFEE INSIGHT
                  {<KeyboardArrowDownIcon style={{ padding: "0 0 0 15px" }} />}
                </p>
                <div className="sub-menu">
                  <div className="triangle">
                    <div className="triangle-point"></div>
                  </div>
                  <ul className="sub-links --flex-center">
                    <li>
                      <Link to={"/trade"} className="--clean-btn">
                        HISTORY of ETHIOPIAN TRADE
                      </Link>
                    </li>
                    <div className="hr" />
                    <li>
                      <Link to={"/coffee-map"} className="--clean-btn">
                        ETHIOPIAN COFFEE MAP
                      </Link>
                    </li>
                    <div className="hr" />
                    <li>
                      <Link to={"/coffee-science"} className="--clean-btn">
                        SCIENCE of COFFEE
                      </Link>
                    </li>
                    <div className="hr" />
                    <li>
                      <Link to={"/didyouknow"} className="--clean-btn">
                        DID YOU KNOW?
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link to={"/updates"} className="--clean-btn">
                  {" "}
                  <p>UPDATES</p>
                </Link>
              </li>
              <li>
                <div className="btn --flex-center">
                  <a href={"/#contacts-section"} className="--clean-btn">
                    {" "}
                    <p>WORK WITH US</p>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavBar;
