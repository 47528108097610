import React from "react";
import "./Header.css"
function Header({ background, title, subtitle }) {
  return (
    <div
      className={`${title || subtitle ? "header-full" : "header-small"} --flex-center-center --column header`}
      // style={{ backgroundImage: `url(${background})` }}
    >
      <div className="header-text">
      <h1 className="--fs-3 --drop-shadow">{title && title}</h1>
      <p className="--fs-3 --drop-shadow">{subtitle && subtitle}</p></div>
      <div className="header-img" style={{ backgroundImage: `url(${background})` }}></div>

    </div>
  );
}

export default Header;
