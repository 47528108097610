import React, { useEffect, useState, useRef } from "react";
import Footer from "../../Widgets/Footer/Footer";
import NavBar from "../../Widgets/NavBar/NavBar";
import "./Home.css";
import { useInView } from "react-intersection-observer";
import Preloader from "../../Components/Preloader/Preloader";
import { images_url } from "../../Constants/constants";
import Partners from "./Partners";
import GoToTop from "../../Functions/GotToTop";
const images_map = {
  banner:
    "https://images.unsplash.com/photo-1697299708650-e4d1ce150d38?auto=format&fit=crop&q=80&w=1744&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  farm: "https://moplaco.com/assets/img/banner/detailBanner.jpg",
};
function Home() {
  const [ref, inView, entry] = useInView({
    rootMargin: "-100px",
  });
  const [farmRef, farmInView, farmEntry] = useInView({
    rootMargin: "-0px",
  });
  const [readMoreRef, readMoreInView, readMoreEntry] = useInView({
    rootMargin: "0px",
  });
  const [ourFarmRef, ourFarmInView, ourFarmEntry] = useInView({
    rootMargin: "0px",
  });
  const [ourFarmCardRef, ourFarmCardInView, ourFarmCardEntry] = useInView({
    rootMargin: "0px",
  });
  const [partnersdRef, partnersInView, partnersEntry] = useInView({
    rootMargin: "0px",
  });

  useEffect(() => {
    const images = Object.values(images_map);
    for (var i = 0; i < images.length; i++) {
      preloadImage(images[i]);
    }
  }, []);

  return (
    <>
      <Preloader />
      <div>
        {/* <NavBar /> */}
      </div>
      <section className="hero-section --flex-center">
        <h1 className="--drop-shadow">
          Preparing coffee <br />
          for the discerning consumer
        </h1>
        <div
          className="hero"
          style={{ backgroundImage: `url(${images_url.banner})` }}
        />
      </section>
      <section ref={ref}>
        <div className="about-section --flex-center">
          <div className={`about --flex-center ${inView && "detransform"}`}>
            <h1>
              A GREEN COFFEE EXPORTER <br />
              DEVOTED TO A HIGH-QUALITY PRODUCT
            </h1>
            <p className="--bold">
              Born in the mountains of Harar-Hirna, in 1940, our owner's soul
              and future was engraved into coffee. Moplaco was born in 1971, in
              Dire Dawa & Harar, the centre of export of Harar coffee. Slowly
              growth came, and the company expanded into Addis, exporting thus
              coffee from all over the country, to all over the world. Since the
              last 15 years, the company focused on the export of high quality
              beans, and furthered its expansion into farming of coffee and also
              processing coffee so as to control the quality from the beginning
              to the end and to be as close to the production as possible.
            </p>
          </div>
        </div>
      </section>
      <section ref={farmRef}>
        <div
          className={`farm --flex-center-center --column   ${
            farmInView && "detransform"
          }`}
        >
          <div className="quote-container --flex-center-center">
            <div className="quote-text-container --semi-bold --flex-center-center --column">
              <p className="quote-text">
                " Good coffee is not an accident. It is the result of hard work,
                innovation starting from the farm, all the way to the final cup.
                "
              </p>
              <p className="quoted --bold">Heleanna Georgalis</p>
            </div>
          </div>
          <div className="steps --flex-center --bold">
            <div className="exporters">
              <div className="steps-image"></div>
              <p className="title">Exporters</p>
            </div>
            <div className="processors">
              <div className="steps-image"></div>
              <p className="title">Processors</p>
            </div>
            <div className="farmers ">
              <div className="steps-image"></div>
              <p className="title">Farmers</p>
            </div>
          </div>
          <div ref={readMoreRef}>
            <div
              className={`btn --width300 transform ${
                readMoreInView && "detransform"
              } `}
            >
              Read More
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="our-farms --flex-center-center --column  --semi-bold">
          <div className=" --flex-center-center" ref={ourFarmRef}>
            <p
              className={`title --bold  --drop-shadow transform ${
                (ourFarmInView || partnersInView) && "detransform"
              } `}
            >
              OUR FARM
            </p>
          </div>
          <div className=" --flex-center-center" ref={ourFarmCardRef}>
            <div
              className={`card transform ${
                (ourFarmCardInView || partnersInView) && "detransform"
              }`}
            >
              <p className="slogan --bold">
                Sheka came to our life unexpectedly and after a lot of search. A
                sanctuary of birds, animals and trees, this place is ideal for
                coffee, and also eco tourism. In this land, we grow, process and
                prepare our very own coffee as well as coffee from the
                neighouring farmers.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section ref={partnersdRef} id="partners">
        <div className="partnership --flex-center-center">
          <h1>PARTNERS</h1>
        </div>
      </section>
      <section className="contact-section" id="contacts-section">
        <div className="map fit-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d717.0228414622405!2d38.81793827079349!3d9.00666016853871!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b8534dc0d0707%3A0x1e4b976f4fab0c4e!2sGalani%20Coffee!5e0!3m2!1sen!2set!4v1629005962074!5m2!1sen!2set"
            width="100%"
            height="100%"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
        <div className="contact-us-form fit-container">
          <form className="fit-container" action="#">
            <div class="--flex-center-center --column">
              <h4 className="--fs-1">Moplaco Trading PLC</h4>
              <h3  className="--fs-2">CONTACT US</h3>
              <p class="--flex-center-center --column --fs-1-5 --bold">
                {`For any information,`} <br />
                {`please contact us directlyvia`} <br/> { `(+251)-116455999 )`}
              </p>
            </div>
            <div class=" --flex-center-center  --column --gap-30px form-controller">
              <div class="form-group --gap-30px --fs-1.5">
                <input class="form-control" type="text" placeholder="Name" />
                <input class="form-control" type="text" placeholder="Phone" />
              </div>
              <div class="form-group --gap-30px">
                <input class="form-control" type="email" placeholder="Email" />
                <input class="form-control" type="text" placeholder="subject" />
              </div>
              <div class="form-group-last">
                <textarea placeholder="Your message"></textarea>
              </div>
            </div>
            <div class="form-group --gap-30px">
              <a class="btn --width200" href="#">
                {/* <div class="btn --borderline --width300" align="center"> */}
                Send
                {/* </div> */}
              </a>
            </div>
          </form>
        </div>
      </section>
      <Footer />
      <GoToTop />

    </>
  );
}
const preloadImage = (src) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.src = src;
    window[src] = image;
    image.style.display = "none";
    image.onload = () => {
      document.body.removeChild(image);
      resolve();
    };
    image.onerror = reject;
    document.body.appendChild(image);
  });
export default Home;
